import React from "react";
import Link from "gatsby-link";
import SEO from "../components/seo";
import "./home.scss";

const IndexPage = (props) => (
    <div location={props.location}>
        <SEO title="Home" language="pl"/>
        <div className="homepage">
            <div className="container">
                <h1 className="homepage__title">
                    Jesteśmy Bat in Web. Jesteśmy w internecie. <br/>
                    Perfekcyjnie wyplatamy sieć. <br/>
                    Mamy Web na karku!
                </h1>
                <p className="homepage__subtitle">
                    Z pasją realizujemy interaktywne projekty<br/>
                    wdrażając <strong>innowacyjną</strong> technologię,<br/>
                    która przekłada się na <strong>efekty</strong>
                </p>
                <Link to={`/contact-us/`} className="btn btn__action">Zapytaj o indywidualną ofertę</Link>
            </div>
        </div>
    </div>
);

export default IndexPage;
